<template>
  <div class="detail-main">
    <div class="header">
      <div class="header-title">智 慧 平 台 数 据 驾 驶 舱</div>
      <!-- 左侧参数详情字样 -->
      <div class="deviceparams">
        <i class="el-icon-s-fold"></i>
        <span style="line-height: 4vh; align-items: middle">设备参数详情</span>
      </div>
      <!-- 右侧时间 -->
      <div class="time">
        <p>{{ time }}</p>
        <div class="avatar">
          <el-popover
            placement="top"
            width="160"
            v-model="visible"
            trigger="hover"
          >
            <p style="padding: 10px 0px">确定要退出登录吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="cancelLogout()"
                >取消</el-button
              >
              <el-button type="primary" size="mini" @click="logout()"
                >确定</el-button
              >
            </div>
            <!-- <img
              src="../../assets/home/avatar.png"
              style="margin-left: 1.04vw"
              alt=""
              slot="reference"
            /> -->
            <img
              style="
                width: 2.5rem;
                border-radius: 50%;
                border-color: black;
                margin-left: 1rem;
              "
              src="../../assets/home/logo.jpg"
              alt=""
              slot="reference"
            />
          </el-popover>
        </div>
      </div>
      <!-- 返回按钮 -->
      <button class="btn" size="medium" @click="backRouter()">返回</button>
    </div>
    <!-- <div class="edit">
        <el-tooltip
          class="item"
          effect="dark"
          content="编辑"
          placement="left-start"
        >
        <img src="../../assets/drive/edit.png" alt="">
        </el-tooltip>
    </div> -->
    <!-- 中间部分 -->
    <div class="main-middle">
      <div class="flex">
        <div class="left">
          <div class="left-table tablelist">
           <div class="key">A相相电压</div>
           <div><span class="value">{{ params.voltageA }}</span><span class="unit">（ V ）</span></div>
           <div class="times">{{ params.gmtModify }}</div>
           <div></div>
            <span class="topLeft line"></span>
            <span class="topRight line"></span>
            <span class="bottomLeft line"></span>
            <span class="bottomRight line"></span>
          </div>
          <div class="left-table tablelist">
           <div class="key">B相相电压</div>
           <div><span class="value">{{ params.voltageB }}</span><span class="unit">（ V ）</span></div>
           <div class="times">{{ params.gmtModify }}</div>
           <div></div>
            <span class="topLeft line"></span>
            <span class="topRight line"></span>
            <span class="bottomLeft line"></span>
            <span class="bottomRight line"></span>
          </div>
          <div class="left-table tablelist">
           <div class="key">C相相电压</div>
           <div><span class="value">{{ params.voltageC }}</span><span class="unit">（ V ）</span></div>
           <div class="times">{{ params.gmtModify }}</div>
           <div></div>
            <span class="topLeft line"></span>
            <span class="topRight line"></span>
            <span class="bottomLeft line"></span>
            <span class="bottomRight line"></span>
          </div>
          <div class="left-table tablelist">
           <div class="key">合相相电压</div>
           <div><span class="value">{{ params.voltageTotal }}</span><span class="unit">（ V ）</span></div>
           <div class="times">{{ params.gmtModify }}</div>
           <div></div>
            <span class="topLeft line"></span>
            <span class="topRight line"></span>
            <span class="bottomLeft line"></span>
            <span class="bottomRight line"></span>
          </div>
          <div class="left-table tablelist">
           <div class="key">AB线电压</div>
           <div><span class="value">{{ params.voltageAb }}</span><span class="unit">（ V ）</span></div>
           <div class="times">{{ params.gmtModify }}</div>
           <div></div>
            <span class="topLeft line"></span>
            <span class="topRight line"></span>
            <span class="bottomLeft line"></span>
            <span class="bottomRight line"></span>
          </div>
          <div class="left-table tablelist">
           <div class="key">BC线电压</div>
           <div><span class="value">{{ params.voltageBc }}</span><span class="unit">（ V ）</span></div>
           <div class="times">{{ params.gmtModify }}</div>
           <div></div>
            <span class="topLeft line"></span>
            <span class="topRight line"></span>
            <span class="bottomLeft line"></span>
            <span class="bottomRight line"></span>
          </div>
          <div class="left-table tablelist">
           <div class="key">CA线电压</div>
           <div><span class="value">{{ params.voltageCa }}</span><span class="unit">（ V ）</span></div>
           <div class="times">{{ params.gmtModify }}</div>
           <div></div>
            <span class="topLeft line"></span>
            <span class="topRight line"></span>
            <span class="bottomLeft line"></span>
            <span class="bottomRight line"></span>
          </div>
          <div class="left-table tablelist">
           <div class="key">合相线电压</div>
           <div><span class="value">{{ params.voltageTotalLine }}</span><span class="unit">（ V ）</span></div>
           <div class="times">{{ params.gmtModify }}</div>
           <div></div>
            <span class="topLeft line"></span>
            <span class="topRight line"></span>
            <span class="bottomLeft line"></span>
            <span class="bottomRight line"></span>
          </div>
          <div class="left-table tablelist">
           <div class="key">A相电流</div>
           <div><span class="value">{{ params.currentA }}</span><span class="unit">（ A ）</span></div>
           <div class="times">{{ params.gmtModify }}</div>
           <div></div>
            <span class="topLeft line"></span>
            <span class="topRight line"></span>
            <span class="bottomLeft line"></span>
            <span class="bottomRight line"></span>
          </div>
          <div class="left-table tablelist">
           <div class="key">B相电流</div>
           <div><span class="value">{{ params.currentB }}</span><span class="unit">（ A ）</span></div>
           <div class="times">{{ params.gmtModify }}</div>
           <div></div>
            <span class="topLeft line"></span>
            <span class="topRight line"></span>
            <span class="bottomLeft line"></span>
            <span class="bottomRight line"></span>
          </div>
          <div class="left-table tablelist">
           <div class="key">C相电流</div>
           <div><span class="value">{{ params.currentC }}</span><span class="unit">（ A ）</span></div>
           <div class="times">{{ params.gmtModify }}</div>
           <div></div>
            <span class="topLeft line"></span>
            <span class="topRight line"></span>
            <span class="bottomLeft line"></span>
            <span class="bottomRight line"></span>
          </div>
          <div class="left-table tablelist">
           <div class="key">合相电流</div>
           <div><span class="value">{{ params.currentTotal }}</span><span class="unit">（ A ）</span></div>
           <div class="times">{{ params.gmtModify }}</div>
           <div></div>
            <span class="topLeft line"></span>
            <span class="topRight line"></span>
            <span class="bottomLeft line"></span>
            <span class="bottomRight line"></span>
          </div>
          <div class="left-table tablelist">
           <div class="key">A相无功功率</div>
           <div><span class="value">{{ params.reactivePowerA }}</span><span class="unit">（ W ）</span></div>
           <div class="times">{{ params.gmtModify }}</div>
           <div></div>
            <span class="topLeft line"></span>
            <span class="topRight line"></span>
            <span class="bottomLeft line"></span>
            <span class="bottomRight line"></span>
          </div>
          <div class="left-table tablelist">
           <div class="key">B相无功功率</div>
           <div><span class="value">{{ params.reactivePowerB }}</span><span class="unit">（ W ）</span></div>
           <div class="times">{{ params.gmtModify }}</div>
           <div></div>
            <span class="topLeft line"></span>
            <span class="topRight line"></span>
            <span class="bottomLeft line"></span>
            <span class="bottomRight line"></span>
          </div>
          <div class="left-table tablelist">
           <div class="key">C相无功功率</div>
           <div><span class="value">{{ params.reactivePowerC }}</span><span class="unit">（ W）</span></div>
           <div class="times">{{ params.gmtModify }}</div>
           <div></div>
            <span class="topLeft line"></span>
            <span class="topRight line"></span>
            <span class="bottomLeft line"></span>
            <span class="bottomRight line"></span>
          </div>
          <div class="left-table tablelist">
           <div class="key">合相无功功率</div>
           <div><span class="value">{{ params.reactivePowerTotal }}</span><span class="unit">（ W ）</span></div>
           <div class="times">{{ params.gmtModify }}</div>
           <div></div>
            <span class="topLeft line"></span>
            <span class="topRight line"></span>
            <span class="bottomLeft line"></span>
            <span class="bottomRight line"></span>
          </div>
          <div class="left-table tablelist">
           <div class="key">A相功率因数</div>
           <div><span class="value">{{ params.powerFactorA }}</span><span class="unit"></span></div>
           <div class="times">{{ params.gmtModify }}</div>
           <div></div>
            <span class="topLeft line"></span>
            <span class="topRight line"></span>
            <span class="bottomLeft line"></span>
            <span class="bottomRight line"></span>
          </div>
          <div class="left-table tablelist">
           <div class="key">B相功率因数</div>
           <div><span class="value">{{ params.powerFactorB }}</span><span class="unit"></span></div>
           <div class="times">{{ params.gmtModify }}</div>
           <div></div>
            <span class="topLeft line"></span>
            <span class="topRight line"></span>
            <span class="bottomLeft line"></span>
            <span class="bottomRight line"></span>
          </div>
          <div class="left-table tablelist">
           <div class="key">C相功率因数</div>
           <div><span class="value">{{ params.powerFactorC }}</span><span class="unit"></span></div>
           <div class="times">{{ params.gmtModify }}</div>
           <div></div>
            <span class="topLeft line"></span>
            <span class="topRight line"></span>
            <span class="bottomLeft line"></span>
            <span class="bottomRight line"></span>
          </div>
          <div class="left-table tablelist">
           <div class="key">合相功率因数</div>
           <div><span class="value">{{ params.powerFactorTotal }}</span><span class="unit"></span></div>
           <div class="times">{{ params.gmtModify }}</div>
           <div></div>
            <span class="topLeft line"></span>
            <span class="topRight line"></span>
            <span class="bottomLeft line"></span>
            <span class="bottomRight line"></span>
          </div>
          <div class="left-table tablelist">
           <div class="key">A相有功功率</div>
           <div><span class="value">{{ params.activePowerA }}</span><span class="unit">（ W ）</span></div>
           <div class="times">{{ params.gmtModify }}</div>
           <div></div>
            <span class="topLeft line"></span>
            <span class="topRight line"></span>
            <span class="bottomLeft line"></span>
            <span class="bottomRight line"></span>
          </div>
          <div class="left-table tablelist">
           <div class="key">B相有功功率</div>
           <div><span class="value">{{ params.activePowerB }}</span><span class="unit">（ W ）</span></div>
           <div class="times">{{ params.gmtModify }}</div>
           <div></div>
            <span class="topLeft line"></span>
            <span class="topRight line"></span>
            <span class="bottomLeft line"></span>
            <span class="bottomRight line"></span>
          </div>
          <div class="left-table tablelist">
           <div class="key">C相有功功率</div>
           <div><span class="value">{{ params.activePowerC }}</span><span class="unit">（ W ）</span></div>
           <div class="times">{{ params.gmtModify }}</div>
           <div></div>
            <span class="topLeft line"></span>
            <span class="topRight line"></span>
            <span class="bottomLeft line"></span>
            <span class="bottomRight line"></span>
          </div>
          <div class="left-table tablelist">
           <div class="key">合相有功功率</div>
           <div><span class="value">{{ params.activePowerTotal }}</span><span class="unit">（ W ）</span></div>
           <div class="times">{{ params.gmtModify }}</div>
           <div></div>
            <span class="topLeft line"></span>
            <span class="topRight line"></span>
            <span class="bottomLeft line"></span>
            <span class="bottomRight line"></span>
          </div>
          <div class="left-table tablelist">
           <div class="key">A相有功电能</div>
           <div><span class="value">{{ params.activeEnergyA }}</span><span class="unit">（ J ）</span></div>
           <div class="times">{{ params.gmtModify }}</div>
           <div></div>
            <span class="topLeft line"></span>
            <span class="topRight line"></span>
            <span class="bottomLeft line"></span>
            <span class="bottomRight line"></span>
          </div>
          <div class="left-table tablelist">
           <div class="key">B相有功电能</div>
           <div><span class="value">{{ params.activeEnergyB }}</span><span class="unit">（ J ）</span></div>
           <div class="times">{{ params.gmtModify }}</div>
           <div></div>
            <span class="topLeft line"></span>
            <span class="topRight line"></span>
            <span class="bottomLeft line"></span>
            <span class="bottomRight line"></span>
          </div>
          <div class="left-table tablelist">
           <div class="key">C相有功电能</div>
           <div><span class="value">{{ params.activeEnergyC }}</span><span class="unit">（ J ）</span></div>
           <div class="times">{{ params.gmtModify }}</div>
           <div></div>
            <span class="topLeft line"></span>
            <span class="topRight line"></span>
            <span class="bottomLeft line"></span>
            <span class="bottomRight line"></span>
          </div>
          <div class="left-table tablelist">
           <div class="key">合相有功电能</div>
           <div><span class="value">{{ params.activeEnergyTotal }}</span><span class="unit">（ J ）</span></div>
           <div class="times">{{ params.gmtModify }}</div>
           <div></div>
            <span class="topLeft line"></span>
            <span class="topRight line"></span>
            <span class="bottomLeft line"></span>
            <span class="bottomRight line"></span>
          </div>
          <div class="left-table tablelist">
           <div class="key">A相无功电能</div>
           <div><span class="value">{{ params.reactiveEnergyA }}</span><span class="unit">（ J ）</span></div>
           <div class="times">{{ params.gmtModify }}</div>
           <div></div>
            <span class="topLeft line"></span>
            <span class="topRight line"></span>
            <span class="bottomLeft line"></span>
            <span class="bottomRight line"></span>
          </div>
          <div class="left-table tablelist">
           <div class="key">B相无功电能</div>
           <div><span class="value">{{ params.reactiveEnergyB }}</span><span class="unit">（ J ）</span></div>
           <div class="times">{{ params.gmtModify }}</div>
           <div></div>
            <span class="topLeft line"></span>
            <span class="topRight line"></span>
            <span class="bottomLeft line"></span>
            <span class="bottomRight line"></span>
          </div>
          <div class="left-table tablelist">
           <div class="key">C相无功电能</div>
           <div><span class="value">{{ params.reactiveEnergyC }}</span><span class="unit">（ J ）</span></div>
           <div class="times">{{ params.gmtModify }}</div>
           <div></div>
            <span class="topLeft line"></span>
            <span class="topRight line"></span>
            <span class="bottomLeft line"></span>
            <span class="bottomRight line"></span>
          </div>
          <div class="left-table tablelist">
           <div class="key">合相无功电能</div>
           <div><span class="value">{{ params.reactiveEnergyTotal }}</span><span class="unit">（ J ）</span></div>
           <div class="times">{{ params.gmtModify }}</div>
           <div></div>
            <span class="topLeft line"></span>
            <span class="topRight line"></span>
            <span class="bottomLeft line"></span>
            <span class="bottomRight line"></span>
          </div>
          <div class="left-table tablelist">
           <div class="key">零序电流</div>
           <div><span class="value">{{ params.zeroSequenceCurrent }}</span><span class="unit">（ A ）</span></div>
           <div class="times">{{ params.gmtModify }}</div>
           <div></div>
            <span class="topLeft line"></span>
            <span class="topRight line"></span>
            <span class="bottomLeft line"></span>
            <span class="bottomRight line"></span>
          </div>
          <div class="left-table tablelist">
           <div class="key">停电或单相掉电</div>
           <div><span class="value">{{ params.powerCut }}</span><span class="unit"></span></div>
           <div class="times">{{ params.gmtModify }}</div>
           <div></div>
            <span class="topLeft line"></span>
            <span class="topRight line"></span>
            <span class="bottomLeft line"></span>
            <span class="bottomRight line"></span>
          </div>
          <div class="left-table tablelist">
           <div class="key">总开关状态</div>
           <div><span class="value">{{ params.switchStatus }}</span><span class="unit"></span></div>
           <div class="times">{{ params.gmtModify }}</div>
           <div></div>
            <span class="topLeft line"></span>
            <span class="topRight line"></span>
            <span class="bottomLeft line"></span>
            <span class="bottomRight line"></span>
          </div>
          <div class="left-table tablelist">
           <div class="key">温度</div>
           <div><span class="value">{{ params.temperature }}</span><span class="unit">（ ℃ ）</span></div>
           <div class="times">{{ params.gmtModify }}</div>
           <div></div>
            <span class="topLeft line"></span>
            <span class="topRight line"></span>
            <span class="bottomLeft line"></span>
            <span class="bottomRight line"></span>
          </div>
          <div class="left-table tablelist">
           <div class="key">湿度</div>
           <div><span class="value">{{ params.humidity }}</span><span class="unit">（ % ）</span></div>
           <div class="times">{{ params.gmtModify }}</div>
           <div></div>
            <span class="topLeft line"></span>
            <span class="topRight line"></span>
            <span class="bottomLeft line"></span>
            <span class="bottomRight line"></span>
          </div>
          <div class="left-table tablelist">
           <div class="key">触点测温</div>
           <div><span class="value">{{ params.zeroSequenceCurrent }}</span><span class="unit">（ ℃ ）</span></div>
           <div class="times">{{ params.gmtModify }}</div>
           <div></div>
            <span class="topLeft line"></span>
            <span class="topRight line"></span>
            <span class="bottomLeft line"></span>
            <span class="bottomRight line"></span>
          </div>

        </div>
      
      </div>
    </div>
 
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 退出登录是否1显示
      visible: false,
      time: "",
      // 定时器
      setTimer: "",
      // 设备id
      id: "",
      // 返回参数
      params:{}
    };
  },
  mounted() {
    // 获取设备id

    if (this.$route.params.id) {
      this.id = this.$route.params.id;
    } else {
      this.id = localStorage.getItem("deviceId");
    }
 
    var that = this;
    // 日期显示
    this.setTimer = setInterval(() => {
      that.Date();
    }, 1000);
    // 参数详情请求
    this.getParamsDetail();
  },
  methods: {
    // 保留几位小数的方法
    fixedNumber(number, i) {
      return Number(number).toFixed(i);
    },
    // 退出登录确定
    logout() {
      //   window.sessionStorage.clear();
      //   this.clearCookie();
      this.$get("/user/logout")
        .then((res) => {
          if (res.code == 1) {
            this.$router.push("/Login");
            this.$message({
              type: "success",
              message: "登出成功!",
            });
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },
    // 退出登录取消
    cancelLogout() {
      this.visible = false;
      this.$message({
        type: "info",
        message: "已取消登出",
      });
    },
  
    // 获取参数详情请求
    getParamsDetail() {
      let param = {
        id: this.id,
      };
      this.$post("/device/lowVoltagePower/listOne", param)
        .then((res) => {
        
          // 主要参数
          this.tableData = res.data.tableData;
          this.params = res.data;
          // 温度
          console.log(this.params)
        })
        .catch(() => {
          console.log("异常");
        });
    },
  
  
    // 获取时间戳
    Date() {
      const nowDate = new Date();
      const date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate(),
        hours: nowDate.getHours(),
        minutes: nowDate.getMinutes(),
        seconds: nowDate.getSeconds(),
      };

      const newmonth = date.month >= 10 ? date.month : "0" + date.month;
      const newday = date.date >= 10 ? date.date : "0" + date.date;
      const newHours = date.hours >= 10 ? date.hours : "0" + date.hours;
      const newminutes = date.minutes >= 10 ? date.minutes : "0" + date.minutes;
      const newseconds = date.seconds >= 10 ? date.seconds : "0" + date.seconds;
      this.time =
        date.year +
        "年" +
        newmonth +
        "月" +
        newday +
        "日" +
        newHours +
        ":" +
        newminutes +
        ":" +
        newseconds;
    },
    // 返回上一级页面
    backRouter() {
      this.$router.push("/CityDevice");
    },
  },
  beforeDestroy() {
    if (this.setTimer) {
      clearInterval(this.setTimer);
    }
  },
};
</script>
<style lang="scss" scoped>
.detail-main {
  background: url("../../assets/drive/big-screen-backimage.svg");
  // background: url("../../assets/drive/背景.png");
  margin: 0px; //外边距0
  width: 100%;
  height: 100%;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  zoom: 1;
  background-color: #07080e;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
  overflow: hidden;
  .header {
    // background: url('../../assets/drive/背景装饰-上.png');
    // background-size: 100% 100%;
    // background-repeat: no-repeat;
    // height: 100%;
    position: relative;
  }
  // 返回按钮
  .btn {
    position: absolute;
    top: 3.24vh;
    left: 1vw;
    // width: 5vw;
    padding: 0 0.521vw;
    height: 4vh;
    text-align: center;
    line-height: 4vh;
    border: 0.05vw solid rgb(11, 166, 167);
    box-shadow: inset 0px 0.05vw 0.89vw rgb(11, 166, 167);
    border-radius: 7px;
    background: linear-gradient(
      0deg,
      rgba(53, 235, 148, 0),
      rgba(53, 235, 148, 0.24) 100%
    );
    color: #fff;
    font-size: 1.04vw;
  }
  // 头部标题
  .header-title {
    width: 100%;
    text-align: center;
    cursor: pointer;
    background: linear-gradient(
      180deg,
      rgba(238, 238, 238, 1),
      rgba(113, 244, 236, 1)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-family: PingFang SC;
    font-size: 2.4vw;
  }
  .edit{
    position: absolute;
    top: 8.5vh;
    right: 5vw;
    
    
    img{
      width: 3vw;
    }
  }
  // 左侧参数设备详情
  .deviceparams {
    position: absolute;
    top: 3.24vh;
    left: 5vw;
    width: 10.2vw;
    min-width: 110px;
    text-align: center;
    white-space: nowrap;
    height: 4vh;
    line-height: 4vh;
    font-size: 1.04vw;
    padding: 0 0.52vw;
    box-sizing: border-box;
    cursor: pointer;
    background: linear-gradient(
      0deg,
      rgba(53, 235, 148, 0),
      rgba(53, 235, 148, 0.24) 100%
    );
    border: 0.05vw solid rgb(11, 166, 167);
    box-shadow: inset 0px 0.05vw 0.89vw rgb(11, 166, 167);
    border-radius: 7px;
    color: #fff;
    .el-icon-s-fold {
      margin: 0 5px 0 0;
      align-items: middle;
    }
  }
  // 右侧时间
  .time {
    font-size: 1.04vw;
    color: #fff;
    position: absolute;
    top: 3.7vh;
    right: 1vw;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  //  参数表格flex
  .flex {
    margin: 10vh 1vw 50px;
    .left{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      height: 75vh;
      overflow-y: scroll;
    }
    .tablelist {
      margin: 10px 2.5%;
      width: 20%;
      cursor: pointer;
      border: 1px solid rgb(25, 49, 60);
      background: linear-gradient(
        180deg,
        rgba(19, 32, 36, 1),
        rgba(98, 255, 246, 0.1)
      );
      padding: 1.5vh 1vw;
      box-sizing: border-box;
      position: relative;
      .key{
        color: #fff;
        font-size: 1.15vw;
        margin: 1.5vh 0;
      }
      .value{
        color: #fff;
        font-size: 1.15vw;
        font-weight: 600;
        margin: 1.5vh 0;
      }
      .unit{
        color: #fff;
        font-size: 1.18vw;
        font-weight: 600;
        margin: 1.5vh 10px;
      }
      .times{
        color: #fff;
        font-size: 1.14vw;
        margin: 1.5vh 0;
      }
      // 小竖线
      .line {
        display: inline-block;
        width: 2px;
        height: 11px;
        background: rgb(98, 255, 246);
        position: absolute;
      }
      .topLeft {
        top: 0;
        left: 0;
      }
      .topRight {
        top: 0;
        right: 0;
      }
      .bottomLeft {
        left: 0;
        bottom: 0;
      }
      .bottomRight {
        bottom: 0;
        right: 0;
      }
    }

   
   
  }
}
//滚动条的宽度
::v-deep ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #e4e4e4;
  border-radius: 3px;
}
//滚动条的滑块
::v-deep ::-webkit-scrollbar-thumb {
  background-color: #033d3d !important;
  border-radius: 3px;
}
// 表头
::v-deep .tableHeaderColor th {
  background: rgb(16, 39, 46) !important;
  color: rgb(211, 230, 248);
  font-family: PingFang SC;
  font-size: 0.83vw;
  line-height: 21px;
  letter-spacing: 0px;
  padding: auto 30px;
  border: 0 !important;
}
/*最外层透明*/
::v-deep .el-table,
::v-deep .el-table__expanded-cell {
  background-color: transparent !important;
}
/* 表格内背景颜色 */
::v-deep .el-table th,
::v-deep .el-table tr,
::v-deep .el-table td {
  border: 0; //去除表格
}
// 表格内字体
::v-deep .el-table {
  color: rgb(211, 230, 248);
  font-family: PingFang SC;
  font-size: 0.73vw;
  letter-spacing: 0px;
  text-align: center;
  &::before {
    display: none;
  }

  // 表格行距
  .el-table__body {
    -webkit-border-vertical-spacing: 10px; // 垂直间距 设置的是行间距
  }
  thead th,
  tbody .el-table__row td {
    padding: 10px 0;
  }
}
// 表格底部边框
.el-table::before {
  background-color: transparent !important;
}
</style>
